<template>
  <div class="content water">
    <banner title="Operations" :image="2"> </banner>

    <div class="section" v-if="type === 'produced'">
      <div class="content_wrapper">
        <h3>Produced Water Gathering</h3>
        <p>
          Taproot operates over 60 miles of produced water gathering pipeline
          with connections to three saltwater disposal wells: EWS #1, NGL C9 and
          BNN (Tallgrass) Wildhorse. The operational capacity of the produced
          water system is greater than 40,000 barrels per day.
        </p>

        <img
          class="image"
          src="../assets/images/map_produced_water_pipelines_medium.png"
          alt="System Map - Produced Water"
        />

        <caption></caption>

        <button @click="back()" class="default">Back</button>
      </div>
    </div>

    <div class="section" v-if="type === 'fresh'">
      <div class="content_wrapper">
        <h3>Fresh Water Supply and Delivery</h3>
        <p>
          Taproot's fresh water system consists of 19 fresh water source wells
          supplying over 50,000 barrels per day of source water to Taproot's one
          million barrel storage pond. Taproot's extensive fresh water
          distribution system includes 20 miles of underground pipe for frac
          delivery throughout its dedicated producer operations. Taproot
          collaborates with other fresh water suppliers in the West Pony area of
          the DJ Basin to support the fresh water needs of producers.
        </p>

        <img
          class="image"
          src="../assets/images/map_fresh_water_pipelines_medium.png"
          alt="System Map - Fresh Water"
        />

        <caption></caption>

        <button @click="back()" class="default">Back</button>
      </div>
    </div>

    <div class="section" v-if="type === 'crude'">
      <div class="content_wrapper">
        <h3>Crude Oil Gathering and Transportation</h3>
        <p>
          Taproot operates over 100 miles of crude gathering and transportation
          pipeline to support over 325,000 dedicated acres. Taproot's crude
          system delivers oil to the Tallgrass Buckingham Terminal for shipping
          on Tallgrass Pony Express and to the Chevron Interconnect for shipping
          on Energy Transfer's Wattenberg Extension to Platteville. Taproot's
          crude system is the only segregated heavy crude system in the DJ
          Basin.
        </p>

        <img
          class="image"
          src="../assets/images/map_crude_pipelines_medium.png"
          alt="System Map - Crude Oil"
        />
        <caption></caption>

        <button @click="back()" class="default">Back</button>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";

export default {
  name: "Tep-Water",
  components: {
    Banner,
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  methods: {
    back() {
      this.$router.push({ path: "/operations" });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
